<template>
  <!-- PRECARGA -->
  <v-overlay :value="isLoading">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'PreLoad',
  data: () => ({
    //
  }),
  computed: {
    ...mapState(['isLoading'])
  }
}
</script>