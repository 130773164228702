<template>
  <v-container>
    <v-form>
    <!-- <v-form @submit.prevent="updateContent()"> -->
      <v-card elevation="1" outlined >
        <v-card-text>
          <v-text-field label="Titulo de la barra superior" v-model="editData.titulo"></v-text-field>
          <br>
          <small class="text--secondary">Texto de banner</small>
          <ckeditor :editor="editor" :config="editorConfig" v-model="editData.action_text"></ckeditor>
          <br>
          <v-text-field label="Botón de acción en banner" v-model="editData.accion_button" ></v-text-field>
          <v-text-field label="Botón más info en banner" v-model="editData.accion_button_2" ></v-text-field>
        </v-card-text>
      </v-card>
      <v-card elevation="1" outlined class="mt-8">
        <v-card-text>
          <v-text-field label="Sub-Titulo de más info" v-model="editData.acerca_subtitle"></v-text-field>
          <br>
          <small class="text--secondary">Titulo de más info</small>
          <ckeditor :editor="editor" :config="editorConfig" v-model="editData.acerca_title"></ckeditor>
          <br>
          <small class="text--secondary">Texto de más info</small>
          <ckeditor :editor="editor" :config="editorConfig" v-model="editData.acerca_text"></ckeditor>
          <br>
        </v-card-text>
      </v-card>
      <v-card elevation="1" outlined class="mt-8">
        <v-card-text>
          <v-text-field label="Texto inferior logos" v-model="editData.proyecto_de"></v-text-field>
        </v-card-text>
      </v-card>
    </v-form>
    <!-- {{editorData}} -->

    <v-btn block color="primary" class="my-8" @click="updateContent()">Actualizar este la página de inicio! 😺</v-btn>
    <!-- <InicioBtn /> -->

  </v-container>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import {db} from '../../main'
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'EditInicio',
  data() {
    return {
      sectionToEdit: 'inicio',
      editData: [],
      editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'numberedList', 'bulletedList', '|', 'insertTable', 'blockQuote', '|', 'mediaEmbed', '|', 'undo', 'redo' ],
      }
    }
  },

  created() {
    this.getContent()
  },

  methods: {
    async getContent() {
      this.loader(true)
      try {
        const doc = await db.collection('contenidos').doc(this.sectionToEdit).get();
        if (!doc.exists) {
          console.log('No existe el documento!');
        } else {
          this.editData = doc.data()
          this.loader(false)
          // console.log('Document data:', doc.data())
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateContent() {
      try {
        let proceed = confirm("⚠ Estas segura(o) que quieres hacer estos cambios? ⚠ 🤓");
        if (proceed) {
          this.loader(true)
          await db.collection('contenidos').doc(this.sectionToEdit).set(this.editData)
          this.getContent()
        } else {
          console.log('Cambios no realizados')
        }
      } catch (error) {
        console.log(error)
      }
    },

  // FUNCIONES GENERALES
    loader(v) {
      this.currentChange([
        {toChange: 'isLoading', value: v}
      ])
    },
    ...mapMutations(['currentChange']),
  },

  computed: {
    ...mapState(['isLoading'])
  }
}
</script>