<template>
  <v-container fluid>
    <PreLoad />
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title>Editor de contenidos</v-card-title>
          <v-card-subtitle>Selecciona la seccion que deseas editar</v-card-subtitle>

          <v-tabs v-model="tab" grow>
            <v-tab v-for="seccion in secciones" :key="seccion.title">
              {{ seccion.title }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="seccion in secciones" :key="seccion.title">
              <!-- <v-card flat>
                <v-card-text>{{ seccion.content }}</v-card-text>
              </v-card> -->
              <component :is="seccion.content"></component>
            </v-tab-item>
          </v-tabs-items>


        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditInicio from '@/components/contenidos/EditInicio'
import EditMetodologia from '@/components/contenidos/EditMetodologia'
import EditAcerca from '@/components/contenidos/EditAcerca'
import EditPolitica from '@/components/contenidos/EditPolitica'

import PreLoad from '@/components/layouts/PreLoad'
export default {
  name: 'Contenidos',
  data: () => ({
    // isLoading: true,
    tab: null,

    secciones: [
      { title: 'Página de inicio', content: 'EditInicio' },
      { title: 'Metodología', content: 'EditMetodologia' },
      { title: 'Acerca de', content: 'EditAcerca' },
      { title: 'Política y manejo de datos', content: 'EditPolitica' },
    ],
    seccionesx: [
      // { title: 'Inicio', icon: 'mdi-view-dashboard', to: '/' },
      // { title: 'Autodiagnóstico', icon: 'mdi-medical-bag', to: '/autodiagnostico' },
      { title: 'Página de inicio', icon: 'mdi-home', to: '/EditInicio' },
      { title: 'Metodología', icon: 'mdi-school', to: '/contentedit/metodologia' },
      { title: 'Acerca de', icon: 'mdi-help-box', to: '/contentedit/acerca' },
      { title: 'Política y manejo de datos', icon: 'mdi-book-open-page-variant', to: '/contentedit/politica' },
    ],
      items: [
        'web', 'shopping', 'videos', 'images', 'news',
      ],
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  }),
  methods: {
    //
  },
  components: {
    EditInicio, EditMetodologia, EditAcerca, EditPolitica, PreLoad
  }
}
</script>