<template>
  <v-container>
    <v-text-field label="Titulo de la sección" v-model="editData.title"></v-text-field>
    <ckeditor :editor="editor" v-model="editData.contentData" :config="editorConfig"></ckeditor>
    <v-btn block color="info" class="mt-2" @click="updateContent()">Actualizar este contenido! 😺</v-btn>
  </v-container>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import {db} from '../../main'
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'EditPolitica',
  data() {
    return {
      sectionToEdit: 'politica',
      editData: [],
      editor: ClassicEditor,
      editorConfig: {
          language: 'es',
          toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'numberedList', 'bulletedList', '|', 'insertTable', 'blockQuote', '|', 'mediaEmbed', '|', 'undo', 'redo' ],
      }
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    async getContent() {
      try {
        this.loader(true)
        const doc = await db.collection('contenidos').doc(this.sectionToEdit).get();
        if (!doc.exists) {
          console.log('No existe el documento!');
        } else {
          this.editData = doc.data()
          this.loader(false)
          // console.log('Document data:', doc.data());
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateContent() {
      try {
        let proceed = confirm("⚠ Estas segura(o) que quieres hacer estos cambios? ⚠ 🤓");
        if (proceed) {
          this.loader(true)
          await db.collection('contenidos').doc(this.sectionToEdit).set(this.editData)
          this.getContent() 
        } else {
          console.log('cambios no realizados')
        }
      } catch (error) {
        console.log(error)
      }
    },
    
  // FUNCIONES GENERALES
    loader(v) {
      this.currentChange([
        {toChange: 'isLoading', value: v}
      ])
    },
    ...mapMutations(['currentChange']),
  },

  computed: {
    ...mapState(['isLoading'])
  }
}
</script>

<style>
.ck-content {
  min-height: 500px;
  }
</style>